@font-face {
    font-family: "f13";
    src: url("/assets/fonts/F13.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "DF";
    src: url("/assets/fonts/DF.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "sbitn";
    src: url("/assets/fonts/SBITN.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "fofbb";
    src: url("/assets/fonts/FOFBB.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "TBL";
    src: url("/assets/fonts/TBL.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "TBL_1";
    src: url("/assets/fonts/TBL_1.TTF") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "ss";
    src: url("/assets/fonts/SS.ttf") format("truetype");
    font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import "bootstrap";


html, body {width: auto!important; overflow-x: hidden!important} 

body {
    color: rgba(225, 0, 0, .97);
    font-family: 'Atkinson Hyperlegible', sans-serif;
}

.bisexual-lighting {
    background-color:grey;
    background-image: linear-gradient(to left, rgba(192, 12, 8, 0.759), rgba(43, 36, 108, 0.866));
    background-size: cover;
    background-position: center;
}

.text-red{color: rgba(225, 0, 0, .97) !important;}
.link-red{color: rgba(225, 0, 0, .97) !important;}
.text-rainbow {
    background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
.text-rainbow-2{
    background-image: 
      linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
    animation: move 1000s linear infinite;
}
@keyframes move {
	to {
		background-position: 4500vh;
	}
}

p{font-size: 1rem;}

.text-shadow{text-shadow: 2px 2px 10px black;}

.fofbb{font-family: "fofbb";}
.ss {font-family: "ss";}
.f13 {font-family: "f13";}
.df {font-family: "DF";}
.font-doublefeature {font-family: "DF";}
.sbitn {font-family: "sbitn";}
.font-tbl{font-family: "TBL";z-index: 2;}
.font-tbl02{font-family: "TBL_1";z-index: 2;}
.font-hl{font-family: 'Atkinson Hyperlegible', sans-serif;}
.font-fofbb{font-family: "fofbb";}

.social a {
    font-size: 1.69em;
    margin: auto 6%;
    color: white;
    text-decoration: none;
}

.text-black {
    color: black;
}


img.absolute_1 {
    position: absolute;
    top: 600px;
    right: 60%;
    z-index: 1;
}

img.absolute_2 {
    position: absolute;
    top: 300px;
    right: 80%;
    z-index: 1;
}

img.absolute_3 {
    position: absolute;
    top: 100px;
    right: 60%;
    z-index: 1;
}

img.absolute_4 {
    position: absolute;
    top: 200px;
    right: 30%;
    z-index: 2;
}

img.absolute_5 {
    position: absolute;
    top: 8%;
    right: 10%;
    z-index: 1;
}

img.absolute_6 {
    position: absolute;
    right: 50%;
    z-index: 1;
}

img.absolute_7 {
    position: absolute;
    top: 5%;
    right: 200%;
    z-index: 1;
    animation-delay: 500ms;
    animation-direction: alternate-reverse;
}


.TBL {
    font-family: "TBL";
    z-index: 2;
}

.TBL_1 {
    font-family: "TBL_1";
    z-index: 2;
}

hr.style-eight {
    overflow: visible;
    /* For IE */
    padding: 0;
    border: none;
    border-top: medium double #333;
    color: #333;
    text-align: center;
}

hr.style-eight:after {
    content: "§";
    display: inline-block;
    position: relative;
    top: -0.7em;
    font-size: 1.5em;
    padding: 0 0.25em;
    background: white;
}
.last-saturday{
     -webkit-text-fill-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke-width: .6px;
    -webkit-text-stroke-color: whitesmoke;
}

body{
    width:100%;
}

#reveal{display:none;}
#reveal:target{display:flex;}
